.home{
    display: flex;
    .home__container{
        flex: 6;
        .widgets,.charts{
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts{
            padding: 5px 20px ;
        }

        .list__container{
            -webkit-box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
            box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;
            .list__title{
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }

}