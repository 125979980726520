.form{
    width: 100%;
    display: flex;
    
    justify-content: center;
    margin-top: 50px;
}
.form__container{
    padding:10px 50px;
    width: 20%;
    background-color: #383CC130;
    min-width:20% ;
    border-radius:5px;
}

.form__container>h1{
    margin-bottom:15px ;
    color: #383CC1;
    font-weight: 900;
    font-size: 40px;
    text-align: center;

}

.form__submit__btn{
    width: 100%;
    background-color: #383CC1;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    margin:10px 0px;
}