.new{
    width: 100%;
    display: flex;
    .new__container{
        flex: 6;
        .top, .bottom {
            -webkit-box-shadow:2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;
            display: flex;
            h1{
                color: gray;
                font-size: 16px;
            }

            .left{
                flex: 1;
                img{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right{
                flex: 2;
                form{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;
                    justify-content: space-around;

                    .form__input{
                        width: 40%;
                        .file__input{
                            display: flex;
                            align-items: center;
                        }

                        .icon{
                            margin-left: 10px;
                            font-size: 35px;
                            color:rgba(0, 0, 255, 0.652);
                            cursor: pointer;
                            text-align: center;
                        }

                        input{
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid lightgray;
                        }

                    }
                    button{
                        width: 40%;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        cursor: pointer;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}