.table__container{
    max-height: 90vh;
    overflow-y: scroll;
    padding: 10px 20px;
}
.datatable{
    height:95vh ;
}
.file__upload__form{
    border: 1px solid lightgray;
    padding: 10px 5px ;
    border-radius:5px ;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.upload__label{
    padding: 5px;
    border-radius: 5px;
    margin-right: 50px;
    font-weight: bold;
    color: blue;
}
.save__btn{
    width: 150px;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}