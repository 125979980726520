.invoice__container{
    display: flex;
    flex-direction: column;
    position: relative;
    .item__box{
        display: flex;
        z-index: 1000;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 128, 128, 0.15); 
        align-items: center;
        justify-content: center;
        .modal__form{
            width: 60%;
            height: 50vh;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            .modal__input__box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .modal__input{
                    width: 60%;
                    border: 1px solid #EAECF0;
                    border-radius: 3px;
                    margin-left:5px ;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 16px;
                    padding: 5px 5px;
                    outline-color:teal ;
                }
            }
            .modal__title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color:rgba(0, 128, 128, 0.15);
                padding: 5px 10px;
                border-radius: 5px;
                color: teal;
            }
            .modal__save{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color:teal;
                border: 1px solid #EAECF0;
                border-radius: 5px;
                font-size: 16px;
                color: white;
                cursor: pointer;
                padding: 10px 30px;
                &:hover{
                    background-color: rgba(0, 128, 128, 0.187);
                    color: teal;        
                }
            }
            
            
            .modal__close{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color:rgba(255, 0, 0, 0.389);
                border: 1px solid #EAECF0;
                border-radius: 5px;
                font-size: 16px;
                color: teal;
                cursor: pointer;
                color: white;
               
            }
        }
        
    }
    
    .back__buton{
        margin: 20px 10px;

        button{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:rgba(0, 128, 128, 0.15);
            border: 1px solid #EAECF0;
            border-radius: 5px;
            font-size: 16px;
            color: teal;
            cursor: pointer;
            &:hover{
                background-color:teal;
                color: white;
            }
            padding: 3px 5px;
        }
    }
    .title{
        margin: 20px 10px;
        font-size: 25px;
        font-weight: bold;
    }
    
    .invoice__box{
        .invoice__input__form{
            width: 100%;
        }
        border: 1px solid #EAECF0;
        margin: 10px 10px;
        padding: 20px 10px;
        display: flex;
        border-radius: 5px;
        .invoice__input__box{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            
            .invoice__input{
                display: flex;
                padding: 0px 10px;
                flex: 1;
                flex-direction: column;
                .search__select__li{
                    cursor: pointer;
                    font-size: 20px;
                    padding: 5px;
                    &:hover{
                        background-color: #EAECF0;
                    }
                }
                input{
                    width: 100%;
                    border: 1px solid #EAECF0;
                    padding: 5px;
                    font-size: 16px;
                    border-radius: 5px;
                    outline-color: rgba(0, 128, 128, 0.611);
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
                select{
                    width: 100%;
                    border: 1px solid #EAECF0;
                    padding: 5px;
                    font-size: 16px;
                    border-radius: 5px;
                    outline-color: rgba(0, 128, 128, 0.611);
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }
        }     
    }
    .item__container{
        // width: 100%;
        border: 1px solid #EAECF0;
        // height:100vh;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        .delete__btn{
            border: none;
            outline: none;
            background: none;
            display: flex;
            align-items:center;
            justify-content: center;
          
            cursor: pointer;
            &:hover{
                color: red;
            }
        }
        
    }
    .invoice__totals{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .invoice__note{
           flex: 8;
           textarea{
                border: 1px solid #EAECF0;
                border-radius: 5px;
                padding: 5px;
                font-size: 15px;
                margin-right: 15px;
                outline-color:teal ;
           }
        }
        .invoice__input__box{
            flex: 4;
            .invoice__total__input{
                display: flex;
                width: 100%;
                align-items: center;
                // justify-content: center;
               
                // flex-direction: column;
                label{
               
                    text-align: left;
                    padding-right: 20px;
                    width: 50%;
                }
                input{
                    border: 1px solid #EAECF0;
                    padding: 5px;
                    font-size: 16px;
                    border-radius: 5px;
                    outline-color: rgba(0, 128, 128, 0.611);
                    margin-bottom: 5px;
                    margin-top: 5px;
                    width: 50%;
                }
        }
        
            
        }
        
    }
    .create__invoice__buton{
            button{width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:rgba(0, 128, 128, 0.15);
            border: 1px solid #EAECF0;
            border-radius: 5px;
            font-size: 16px;
            color: teal;
            cursor: pointer;
            &:hover{
                background-color:teal;
                color: white;
            }
            padding: 5px 5px;}
    }
    
}