.invoice{
    display: flex;
    .invoice__container{
        flex:6 ;

        .invoice__header__box{
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 15px;
            padding: 10px ;
            .invoice__header__heading{
                font-weight:bold;
            }

            .invoice__add__button{
                padding:10px;
                border: none;
                background-color: teal;
                border-radius: 5px;
                cursor: pointer;
                color: white;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .invoice__analytics{
            border: 1px solid #EAECF0;
            margin: 15px 10px ;
            padding: 10px;
            height: max-content;
            background-color: #E7F0FA;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            .invoice__heading__details{
                flex: 6;
                .details__amt{
                    margin-bottom: 20px;
                }
            }
            .invoice__heading_chart{
                flex: 6;
                display: flex;
                align-items: center;
            }
        }
        .invoice__table{
            border: 1px solid #EAECF0;
            margin: 15px 10px ;
            padding: 10px;
            height: max-content;

        }
    }
}