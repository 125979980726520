.widget{
    display: flex;
    flex: 1;
    padding: 10px;
    justify-content: space-between;
    -webkit-box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
    box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
    border-radius: 8px;
    height: 100px;

    .left,.right{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: lightgray;
        }
        .counter{
            font-size: 12px;
            font-weight: 300;
        }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid lightgray;
        }
        .link:hover{
            cursor: pointer;
        }
        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;
            &.negative{
                color: red;
            }
            &.positive{
                color: green;
            }
        }

        .icon{
            font-size: 18px;
            padding: 5px;
            border-radius:5px;
            align-self: flex-end;
        }
    }
}