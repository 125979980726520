.form__input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.form__input>input{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    border:1px solid gray;
}

.form__input>input:focus{
    outline-color: #383CC1;
}

.errormessage{
    font-size: 12px;
    color: red;
    margin-bottom: 10px;
    text-align: justify;
    display: none;
}

.form__input>input:invalid[focused="true"] ~ .errormessage{
    display: block;
}