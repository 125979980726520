.invoice__detail__container{
    display: flex;
    flex-direction: column;
    position: relative;
    .item__box{
        display: flex;
        z-index: 1000;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 128, 128, 0.15); 
        align-items: center;
        justify-content: center;
        .modal__form{
            width: 60%;
            height: max-content;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            .modal__input__box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .modal__input{
                    width: 60%;
                    border: 1px solid #EAECF0;
                    border-radius: 3px;
                    margin-left:5px ;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 16px;
                    padding: 5px 5px;
                    outline-color:teal ;
                }
                .modal__select{
                    width: 62%;
                    border: 1px solid #EAECF0;
                    border-radius: 3px;
                    margin-left:5px ;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 16px;
                    padding: 5px 5px;
                    outline-color:teal ;
                }
            }
            .modal__title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color:rgba(0, 128, 128, 0.15);
                padding: 5px 10px;
                border-radius: 5px;
                color: teal;
            }
            .modal__save{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color:teal;
                border: 1px solid #EAECF0;
                border-radius: 5px;
                font-size: 16px;
                color: white;
                cursor: pointer;
                padding: 10px 30px;
                &:hover{
                    background-color: rgba(0, 128, 128, 0.187);
                    color: teal;        
                }
            }
            
            
            .modal__close{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color:rgba(255, 0, 0, 0.389);
                border: 1px solid #EAECF0;
                border-radius: 5px;
                font-size: 16px;
                color: teal;
                cursor: pointer;
                color: white;
               
            }
        }
        
    }
    .back__buton{
        margin: 20px 10px;

        button{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:rgba(0, 128, 128, 0.15);
            border: 1px solid #EAECF0;
            border-radius: 5px;
            font-size: 16px;
            color: teal;
            cursor: pointer;
            &:hover{
                background-color:teal;
                color: white;
            }
            padding: 3px 5px;
        }
        
    }
    .title{
        flex:1;
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin: 0px 10px;
        font-size: 20px;
        font-weight: bold;
        // color:red;
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 20px;
            background-color: rgba(0, 128, 128, 0.738);
            border: none;
            font-size: 20px;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
                background-color: teal  ;
            }
            
        }
       
    }
    .detail__box{
        display: flex;
        justify-content:space-between;
        padding: 10px;
        gap: 10px;
        .left__box{
            flex: 8;
            display: flex;
            flex-direction: column;
            
        }
        .right__box{
            flex: 4;
            display: flex;
            flex-direction: column;
        }
        .card{
            border: 1px solid #EAECF0;
            border-radius: 5px;
            margin-top: 10px;
            .card__heder{
                border-bottom:1px solid #EAECF0 ;
                padding: 15px;
                font-size: 20px;
                font-weight: 600;
                background-color: rgba(0, 128, 128, 0.05);
            }
            .card__body{
                display: flex;
                flex-wrap: wrap;
                
                
                .card__body__item{
                    display: flex;
                    flex-direction: column;
                    padding: 15px;
                    .item__heading{
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }
                    .item__value{
                        font-size: 14px;
                        color: gray;
                        margin-bottom: 5px;

                    }
                }
                .action__buttons{
                    padding: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    button{
                        margin: 5px;
                        width: 150px;
                        padding: 5px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 5px;
                        color: teal;
                        background:none;
                        border: 1px solid rgba(0, 128, 128, 0.871);
                        cursor: pointer;
                        &:hover{
                            background-color:rgba(0, 128, 128, 0.05) ;
                            border: 1px solid teal;
                        }

                    }
                }
                ul{
                    list-style-type: disc;
                    .history__log{
                        font-size: 12px;
                        padding: 10px 0px;
                    }
                }
             }
             .card__footer{
                width: 100%;
                border-top:1px solid #EAECF0 ;
                background-color: rgba(0, 128, 128, 0.05);
                ul{
                    list-style: none;
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 10px 0px;
                        
                        cursor: pointer;
                        &:hover{
                            background-color: rgba(0, 128, 128, 0.357);
                        }
                    }
                }
             }
        }   
    }
}