.navbar{
    height: 50px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    .navbar__wrapper{
        width: 100%;
        padding: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar__search{
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;   
            input{
                border: none;
                outline: none;
                background-color: transparent;
                &::placeholder{
                    font-size: 12px;
                }
            }
        }

        .navbar__items{
            display: flex;
            align-items: center;
            .navbar__item{
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;

                .navbar__icon{
                    font-size: 20px;
                }

                .navbar__avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .counter{
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: red;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px; 
                }
            }
        }
    }
}