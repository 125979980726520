.header__container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    .form__box{
        border: 1px solid #EAECF0;
        padding: 10px;
        border-radius: 5px;
        form{

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        .input__container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 5px 10px;
            width: 30%;
            input{
                
                border: 1px solid #EAECF0;
                padding: 5px;
                font-size: 14px;
                color: gray;
                outline-color:teal ;
                border-radius: 5px;

            }

        }
        .header__submit__btn{
            padding: 10px 20px ;
            border: 1px solid #EAECF0;
            border-radius: 5px;
            background-color: teal;
            color: white;
            margin-left: 10px;
            width: 15%;
            margin-top: 20px;
            cursor: pointer;
            &:hover{
                background-color: rgba(0, 128, 128, 0.723);
            }
        }
        }
        
    }
}