.single{
    display: flex;
    .single__container{
        flex: 6;
        .top{
            padding: 20px;
            display: flex;
            gap: 20px;

            .left{
                flex:1;
                -webkit-box-shadow:2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
                padding: 20px;
                position:relative;
                .img__container{
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    .item__image{
                        flex: 3.5;
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    .details{
                        flex: 8.5;
                        .item__title{
                            margin-bottom: 10px;
                            color: #555;
                        }
                        .detail__item{
                            margin-bottom: 5px;
                            font-size: 14px;
                            .item__key{
                                font-weight: bold;
                                color: gray;
                                margin-right: 5px;
                            }
                            .item__value{
                                font-weight: 300;
                            }
                        }
                        
                    }
                }
                .edit__button{
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    background-color: #7551f818;
                    border-bottom-left-radius: 5px;
                    font-size: 12px;
                    color:#7451f8 ;
                    cursor: pointer;
                }

                
            }

            .right{
                flex: 2;
            }
        }
        .bottom{
            padding: 10px;
            margin: 10px 20px;
            -webkit-box-shadow:2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
        }
    }
    .title{
        font-size: 16px;
        margin-bottom: 20px;
        color: gray;
    }
}