.import{
    width: 100%;
    display: flex;
    
    .import__container{
        flex: 6;
        .top, .bottom {
            -webkit-box-shadow:2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;
            display: flex;
            flex-direction: column;
            .save__button{
                width: 150px;
                padding: 10px;
                border: none;
                background-color: teal;
                color: white;
                cursor: pointer;
                font-weight: bold;
                margin-top: 10px;
            }
            

            
        }
    }
}