.sidebar{
    flex: 1;
    border-right: 0.5px solid rgb(238, 230, 230);
    min-height: 100vh;
    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.5px solid rgb(238, 230, 230);
        .logo{
            color: purple;
            font-size: 20px;
            font-weight: 800;
        }
    }
    
    .center{
        padding-left: 10px;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            .title{
                font-style: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px; 
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;
                &:hover{
                    background-color: #ece8ff;

                }

                .icon{
                    font: size 18px;
                    color: #7451f8;
                }

                span{
                    font-size: 13px;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;

                }
            }
        }
    }
    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;
        .color__option{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            margin: 5px;
            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue;
            }

        }

    }
}