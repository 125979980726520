.com__main {
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 10px;
        margin-top: 20px;

        button {
            margin-top: 30px;
            padding: 10px;
            margin-left: 165px;
            border: none;
            background-color: teal;
            border-radius: 5px;
            cursor: pointer;
            color: white;
            text-align: right;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table__data {
        height: 50vh;
        padding: 0 10px;

        .cell__action {
            display: flex;
            align-items: center;
            gap: 15px;

            .view__button {
                padding: 2px 5px;
                border: 1px dotted rgba(0, 0, 255, 0.476);
                border-radius: 5px;
                color: darkblue;
                cursor: pointer;
            }

            .delete__button {
                padding: 2px 5px;
                border: 1px dotted rgba(220, 20, 60, 0.513);
                color: crimson;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }

    .edit__btn__box {
        padding: 0px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .edit_btn {
           
            width: 150px;
            padding: 10px;
            border: none;
            background-color: teal;
            border-radius: 5px;
            cursor: pointer;
            color: white;
            text-align: right;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
        }
    }


    .form__box {
        border: 1px solid #EAECF0;
        margin: 10px 10px;
        padding: 20px 10px;
        display: flex;
        border-radius: 5px;

        .input__form {

            display: flex;
            padding: 0px 10px;
            flex: 1;
            flex-direction: column;

            .input__container {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                label {
                    width: 15%;
                }

                input {
                    width: 83.8%;
                    border: 1px solid #EAECF0;
                    padding: 5px;
                    font-size: 16px;
                    border-radius: 5px;
                    outline-color: rgba(0, 128, 128, 0.611);
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }

            .submit_btn {
                margin-top: 30px;
                width: 150px;
                padding: 10px;
                margin-left: 165px;
                border: none;
                background-color: teal;
                border-radius: 5px;
                cursor: pointer;
                color: white;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }


    }


}