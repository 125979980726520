.featured{
    flex:2;
    -webkit-box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
    box-shadow:2px 4px 10px 1px rgba(201,201,201,0.47);
    padding: 10px;
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font-size: 16px;
            font-weight: 500;

        }
    }

    .bottom{
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .featured__chart{
            width: 100px;
            height: 100px;

        }

        .title{
            font-weight: 500;
            color: gray;
        }

        .amount{
            font-size: 30px;

        }

        .desc{
            font-weight: 300;
            color: gray;
            text-align: center;
            font-size: 12px;
        }

        .summary{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item__container{
                text-align: center;
                .item__title{
                    font-size: 14px;
                    color: gray;
                }
                .item__result{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;

                    &.positive{
                        color: green;
                    }

                    &.negative{
                        color: red;
                    }
                }
            }
        }
    }

}