.list{
    display: flex;
    .list__container{
        flex: 6;
        .button__container{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .add__button{
                padding:10px;
                border: none;
                background-color: teal;
                border-radius: 5px;
                margin: 10px 5px;
                color: white;
                text-align: right;
                font-size: 12px;
                font-weight: bold;
            }
        }
        
    }
}